<template>
    <div id="Alerts">
        <div class="row" v-if="!this.alert">
            <div class="col-12">
                <card type="primary">
                    <div class="row" id="root">
                        <div class="col">
                            <h4 class="card-title pb-3">Programme/Inquiry Alerts</h4>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-3">
                            <div v-if="usingProducingOfficeDropdown()" class="form-group pt-2">
                                <label for="selProducingOffice">Filter By Production Office</label>
                                <v-select
                                        id="selProducingOffice"
                                        aria-describedby="spnProducingOfficeError"
                                        :class="{ 'is-invalid': classTypeHasError }"
                                        label="regional_company_name"
                                        :options="$store.getters.producingOffices"
                                        :disabled="$store.getters.producingOffices.length===0"
                                        :value="this.selectedProductionOffice"
                                        :clearable="true"
                                        placeholder="Select a Producing Office"
                                        @input="office => changeProducingOffice(office)"
                                ></v-select>
                                <span
                                        id="spnProducingOfficeError"
                                        class="invalid-feedback"
                                        v-if="producingOfficeHasError"
                                >{{ producingOfficeError }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div v-if="$store.getters.isLoggedIn" class="mt-3">
                        <div v-if="loadingAlerts">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <div v-else>
                            <b-row>
                                <b-col sm="12" class="my-1">
                                    <b-table striped hover outlined :items="alertsList" :fields="fields"
                                             :current-page="currentPage"
                                             :per-page="perPage"
                                             :filter="filter"
                                             :filter-included-fields="filterOn"
                                             :sort-by.sync="sortBy"
                                             :sort-desc.sync="sortDesc">

                                        <template v-slot:cell(flag_is_read)="row">
                                            <b-badge variant="primary" v-if="row.item.flag_is_read === 0">Unread</b-badge>
                                            <b-badge variant="primary" v-else>Read</b-badge>
                                        </template>

                                        <template v-slot:cell(button)="row">
                                            <b-button size="sm" @click="showAlert(row.item)">
                                                View
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                            <div class="row">
                                <div class="col-12">
                                    <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="left"
                                            size="sm"
                                            class="my-0"
                                    ></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col">
                            <p>Log in to see your Alerts</p>
                            <login-modal @logged-in="reloadProgrammes"></login-modal>
                            <button class="btn btn-primary" v-b-modal.modal-login>Login</button>
                        </div>
                    </div>
                </card >
            </div>
        </div>
        <div class="row">
            <div class="col">
                <alert-overview v-if="this.alert" :alert="this.alert" @hideAlert="hideAlert()"></alert-overview>
            </div>
        </div>

        <div class="row">
            <div class="col">

            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Card from "../components/Cards/Card";
import LoginModal from "../components/LoginModal";
import FeaturesMixin from "@/mixins/features.mixin";
import AlertOverview from "../components/Alerts/AlertOverview.vue";
export default {
    name: "UpcomingRenewals",
    components: {
        Card,
        LoginModal,
        AlertOverview,
    },
    mixins: [FeaturesMixin],
    data: () => {
        return {
            message: "This is the message",
            loadingAlerts: true,
            loadingProgrammes: true,
            alertsList: [],
            alert: null,
            selectedProductionOffice: [],
            programmeList: [],
            // Table settings
            fields: [
                { key: 'flag_is_read', label: 'Status', sortable: true },
                { key: 'programme.client.client_name', label: 'Client', sortable: true },
                { key: 'programme.producing_company.regional_company_name', label: 'Office', sortable: true },
                { key: 'message_subject', label: 'Subject', sortable: true },
                { key: 'programme.master_policy_name', label: 'Programme', sortable: true },
                { key: 'date_created', label: 'Date Created', sortable: true },
                { key: 'button', label: '', sortable: false },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: 'date_created',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        };
    },
    props: {
        classTypeHasError: {
            type: Boolean,
            default: false,
        },
        classTypeError: {
            type: String,
            default: ""
        },
        producingOfficeHasError: {
            type: Boolean,
            default: false,
        },
        producingOfficeError: {
            type: String,
            default: ""
        },
    },
    methods: {
        async getAlerts() {
            this.loadingAlerts = true;

            try {
                let { data } = await axios.post(
                    process.env.VUE_APP_API_ENDPOINT + "/programme/alerts",
                    {
                        company: this.selectedProductionOffice
                    },
                    {
                        withCredentials: true
                    }
                );

                if(data.alerts) {
                    this.alertsList = data.alerts;
                    this.totalRows = data.alerts.length;
                    this.loadingAlerts = false;
                }

            } catch (err) {
                console.error(err);
            }
        },
        showAlert(alert)
        {
            this.alert = alert;

            // TODO:: Mark the message as read.
        },
        hideAlert()
        {
            this.alert = null;
        },
        changeProducingOffice(office) {
            if (office) {
                this.selectedProductionOffice = office;
                this.getAlerts();
            }
        },

    },
    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.getAlerts();
        }
    }
};


</script>