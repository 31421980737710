<template>
    <div id="Threads">

        <div class="row my-3">
            <div class="col">
                <b-button @click="hideAlert()">Go Back</b-button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <card type="primary">
                    <div class="row" id="root">
                        <div class="col">
                            <h2 class="card-title">{{ this.alert.message_subject }}</h2>
                            <hr class="my-3">
                            <span v-html="this.alert.message_body"></span>
                            <br />
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import LoginModal from "@/components/LoginModal.vue";
import Card from "@/components/Cards/Card";

export default {
    name: "AlertOverview",
    props: ['alert'],
    components: {
        LoginModal,
        Card,
    },
    data: () => {
        return {
            loadingAlerts: true,
            errors: null,
        };
    },
    methods: {
        async hideAlert()
        {
            this.$emit('hideAlert')
        },
    }
}
</script>